<template>
  <div class="container">
    <div class="bg-cover"></div>
    <div class="container content">
      <div id="login-bg">
        <div class="form-box">
          <div class="site-title">
            <div class="title-box">
              <h1 id="login-title">GEPI</h1>
              <div class="sidebar-icon"></div>
            </div>
          </div>
          <button
            class="tablink"
            v-on:click="setTab('jhu')"
            v-bind:class="{ active: this.activeTab === 'jhu' }"
          >
            JHU Login
          </button>
          <button
            class="tablink"
            v-on:click="setTab('partner')"
            v-bind:class="{ active: this.activeTab === 'partner' }"
          >
            Partner Login
          </button>
          <div id="jhu" class="tabcontent" v-if="this.activeTab === 'jhu'">
            <p>Use this button to log in as a JHU/GEPI Employee.</p>
            <div class="button-box">
              <div class="button-container">
                <a :href="generateOAuthUrl('jhu')"
                  ><button class="button jhu-button" type="button">
                    <span
                      ><i class="fab fa-google-plus-g"></i> Sign in with
                      JHU</span
                    >
                  </button></a
                >
              </div>
            </div>
          </div>
          <div
            id="partner"
            class="tabcontent"
            v-if="this.activeTab === 'partner'"
          >
            <form @submit.prevent="login" method="post">
              <h3 class="form-title">Username</h3>
              <input
                type="text"
                id="inputEmail"
                class="input-box"
                placeholder="Username"
                autofocus=""
                v-model="username"
                name="username"
              />
              <h3 class="form-title">Password</h3>
              <input
                type="password"
                id="inputPassword"
                class="input-box"
                placeholder="Password"
                v-model="password"
                name="password"
              />

              <div class="button-box">
                <div class="button-container">
                  <button class="button jhu-button" type="submit">
                    <i class="fas fa-sign-in-alt"></i> Sign in
                  </button>
                </div>
              </div>
              <br />
              <a href="#" id="forgot-pass">Forgot password?</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="login">
      <h1>Login</h1>
      <div class="card" style="width: 18rem;">
        <a :href="$store.getters.oauthUrl('google-oauth2')">
        <button type="button" class="btn btn-link"
          >Google</button></a>
      </div>
    </div> -->
</template>

<script>
import axios from "axios";
export default {
  components: {},
  title() {
    return `GEPI: Login`;
  },
  data() {
    return {
      username: "",
      password: "",
      activeTab: "jhu",
    };
  },
  methods: {
    login() {
      const vm = this;
      const { username, password } = this;
      this.$store
        .dispatch("AUTH_LOGIN", {
          username,
          password,
        })
        .then(() => {
          const redirect = vm.$route.query.redirect || "/";
          vm.$router.push(redirect);
        });
      this.username = "";
      this.password = "";
    },
    setTab(tab) {
      this.activeTab = tab;
    },
    generateOAuthUrl(provider) {
      this.$store.dispatch("saveRedirectPath", this.$route.query.redirect);
      return this.$store.getters.oauthUrl(provider);
    },
  },
};
</script>

<style scoped>
/* Page CSS */
.tablink {
  display: none;
}
@font-face {
  font-family: "arvo";
  src: url("../assets/fonts/Arvo-Bold.ttf");
}

form {
  display: grid;
  padding-top: 10px;
}

h3.form-title {
  margin: auto;
  padding: 30px 0px 10px 0px;
  font-size: 15px;
  color: rgb(56, 56, 56);
  width: 50%;
}

.input-box {
  margin: auto;
}

.tabcontent {
  display: grid;
  padding: 0px;
}

p {
  text-align: center;
  color: rgb(56, 56, 56);
  margin: 40px 0px 10px 0px;
}

div.content {
  margin-left: 0px;
  padding: 0px;
}

.form-box {
  margin: 5% 30% 10% 30%;
  padding: 30px;
  background: white;
  border-radius: 4px;
}

h1 {
  font-family: "arvo";
  color: rgb(56, 56, 56);
  font-size: 55px;
  margin: 0px 10px 0px 0px;
}

.site-title {
  display: flex;
  width: 100%;
  margin: 40px 0px;
}

.title-box {
  margin: auto;
  display: flex;
  background: white;
  height: 50px;
  padding-top: 0px;
}

.sidebar-icon {
  margin: 0px;
  height: 60px;
  width: 60px;
}

.button-box {
  margin: auto;
  display: flex;
}

.login-hr {
  width: 100%;
  border: solid 1px #b4b4b4;
}

.button-container {
  display: flex;
  width: 100%;
  margin: 40px 0px;
}

#forgot-pass {
  text-align: center;
  margin-bottom: 30px;
  font-family: "roboto";
}

#login-bg {
  background: url("../assets/images/login-bg.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 900px;
  height: 100%;
  display: flow-root;
}

.button {
  background-color: var(--purple);
  border: none;
  color: var(--purple);
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0px rgb(153, 153, 153);
}

.button.jhu-button {
  background-color: var(--purple);
  color: var(--white);
  margin: 0px;
  width: 210px;
}

.button.jhu-button:hover {
  background-color: #be68b0;
}

.input-box {
  float: right;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 4px;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 20px;
  margin: 4px 25%;
}

.navbar-outline {
  border-style: solid;
  border-color: rgb(240, 240, 240);
  border-width: 1px;
}

.input-box:focus {
  outline: solid 1px #cfcfcf;
}

.input-box::placeholder {
  color: rgb(163, 163, 163);
}

/* Responsive formatting */
@media screen and (max-width: 1000px) {
  .form-box {
    margin: 10% 20% 30% 20%;
    min-width: 300px;
  }
}
@media screen and (max-width: 500px) {
  .form-box {
    margin: 5% 10% 30% 5%;
    min-width: 300px;
  }
}
</style>
